import React, { useEffect, useState } from "react";
import { Button, message } from "antd";
import SendRequestForm from "../components/SendRequestForm"; 
import PolygonEditor from "../components/PolygonEditor";
import { useMap } from "../app/MapContext";
import { TileWMS } from 'ol/source';
import bbox from "@turf/bbox";
import TileLayer from "ol/layer/Tile";
import axios from "axios";

const SendRequest: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(1); // Adım numarası: 1 -> Başlangıç, 2 -> Harita/Polygon, 3 -> Form, 4 -> Son
  const [polygonData, setPolygonData] = useState(null); // GeoJSON polygon verisi
  const [intersectsFeatureIds, setIntersectsFeatureIds] = useState<string[]>([]);
  const [latestPolygonData, setLatestPolygonData] = useState<any>(null); // Store latest polygon data for submission
  const [isFetchingIds, setIsFetchingIds] = useState(false); // Fetch işlemi için loading durumu

  const [formData, setFormData] = useState(null); // Formdan gelen veriler

  const { map } = useMap();

  useEffect(() => {
    if (!map || !latestPolygonData) return;

    const fetchIdsInPolygon = async (polygonGeoJSON: any): Promise<string[]> => {
      const ids: string[] = [];
      const extent = bbox(polygonGeoJSON);

      for (const layer of map.getLayers().getArray()) {
        if (layer instanceof TileLayer) {
          const source = layer.getSource();

          if (source instanceof TileWMS) {
            const params = source.getParams();
            const layerName = params.LAYERS;

            if (layerName && layerName.startsWith("roite")) {
              const layerUrl = source.getUrls()?.[0];

              if (layerUrl) {
                const wfsUrl = `${layerUrl.replace('/wms', '/wfs')}?service=WFS&version=1.0.0&request=GetFeature&typeName=${layerName}&outputFormat=application/json&CQL_FILTER=BBOX(geom, ${extent.join(",")}, 'EPSG:3857')`;

                try {
                  const response = await axios.get(wfsUrl);
                  const layerIds = response.data.features.map((feature: any) => feature.id);
                  ids.push(...layerIds);
                } catch (error) {
                  console.error(`Error fetching data for layer ${layerName}:`, error);
                }
              }
            }
          }
        }
      }

      return ids;
    };

    const fetchData = async () => {
      setIsFetchingIds(true);
      const ids = await fetchIdsInPolygon(latestPolygonData);
      setIntersectsFeatureIds(ids);
      setIsFetchingIds(false);
    };

    fetchData();
  }, [latestPolygonData, map]);

  const handleNewRequest = () => {
    setCurrentStep(2); // Geçiş yap
    message.info("Please draw or edit the polygon on the map.");
  };

  const handleNextStep = () => {
    setLatestPolygonData(polygonData); // Save the polygon before moving
    setCurrentStep(3); // Geçiş yap (form)
  };

  const handleSubmitForm = (values: any) => {
    if (isFetchingIds) return; // Disable form submission during fetching
    setFormData(values); // Form bilgilerini kaydet
    setCurrentStep(4); // Son adıma geç
  };

  const renderCompletionMessage = () => (
    <div style={{ textAlign: "center", margin: 20 }}>
      <h2>Once the payment process is completed, the relevant documents will be sent to your e-mail.</h2>

      <Button onClick={() => setCurrentStep(1)}>New Request</Button>
      {/* <h1>{JSON.stringify(formData)}</h1> */}
    </div>
  );

  return (
    <div>
      {currentStep === 1 && (
        <div style={{ textAlign: "center", margin: 20 }}>
          <Button type="primary" onClick={handleNewRequest}>
            New Request
          </Button>
        </div>
      )}

      {currentStep === 2 && (
        <div>
          <PolygonEditor onPolygonSave={setPolygonData} />
          <div style={{ textAlign: "center", margin: 20 }}>
            <Button style={{ marginRight: "5px" }} onClick={() => setCurrentStep(1)}>
              Cancel
            </Button>
            <Button type="primary" onClick={() => handleNextStep()}>
        Complete Draw
      </Button>
        </div>
        </div>
      )}

      {currentStep === 3 && (
        <>
          <SendRequestForm
            onSubmit={handleSubmitForm}
            polygonData={polygonData}
            isLoading={isFetchingIds} // Disable form during fetch
            intersectsFeatureIds={intersectsFeatureIds}
            bbox={bbox(latestPolygonData).join(',')}
          />
          <Button onClick={() => setCurrentStep(1)}>Cancel</Button>
        </>
      )}

      {currentStep === 4 && renderCompletionMessage()}
    </div>
  );
};

export default SendRequest;
