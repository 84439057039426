import React, { useState, useMemo } from "react";
import axios from "axios";
import { Input, Button, List, Spin, message } from "antd";
import { CloseCircleOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useMap } from "../app/MapContext";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import OLFeature from "ol/Feature"; // OpenLayers Feature sınıfı
import Point from "ol/geom/Point";
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import Fill from "ol/style/Fill";
import CircleStyle from "ol/style/Circle";

// Arayüz adını AddressFeature olarak değiştiriyoruz
interface AddressFeature {
  id: string;
  geometry: { coordinates: [number, number] };
  properties: { gfullname: string };
}

const AddressSearch: React.FC = () => {
  const { map } = useMap();
  const [query, setQuery] = useState<string>("");
  const [results, setResults] = useState<AddressFeature[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const source = useMemo(() => new VectorSource(), []);
  const vectorLayer = useMemo(() => {
    const layer = new VectorLayer({
      source,
      style: new Style({
        image: new CircleStyle({
          radius: 10,
          fill: new Fill({ color: "#a60510" }),
          stroke: new Stroke({ color: "#ffffff", width: 2 }),
        }),
      }),
    });
    (layer as any).id = "addressMarker";
    return layer;
  }, [source]);

  const searchAddresses = async () => {
    if (!query) return;
    setLoading(true);
    try {
      const cqlFilter = encodeURIComponent(
        query
          .split(" ")
          .map((term: string) => `search_column LIKE '%${term.toLowerCase()}%'`)
          .join(" AND ")
      );
      const url = `https://map.cadastru.md/geoserver/w_rsuat/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=apl:mv_punct_constr&maxFeatures=25&srsname=EPSG:3857&outputFormat=application/json&CQL_FILTER=${cqlFilter}`;
      const response = await axios.get(url, {
        auth: {
          username: "roite1",
          password: "K6BPnrxrSTqc8Mcyl0MS",
        },
      });
      const features = Array.isArray(response.data.features)
        ? response.data.features.map((feature: any) => ({
            id: feature.id,
            geometry: feature.geometry,
            properties: feature.properties,
          }))
        : [];
      setResults(features);
    } catch (error) {
      console.error("Address search failed:", error);
      message.error("An error occurred during the address search.");
      setResults([]);
    } finally {
      setLoading(false);
    }
  };
  
  const zoomToFeature = (coordinates: [number, number]) => {
    if (map) {
      // const transformedCoords = fromLonLat(coordinates);
      map.getView().animate({ center: coordinates, zoom: 17, duration: 1000 });

      // Marker işlemleri
      const existingLayer = map
        .getLayers()
        .getArray()
        .find((layer: any) => layer.id === "addressMarker");

      if (!existingLayer) {
        map.addLayer(vectorLayer);
      }
      const feature = new OLFeature({
        geometry: new Point(coordinates),
      });
      source.clear(); // Önceki markerları temizle
      source.addFeature(feature);
    }
  };

  const clearSearch = () => {
    setQuery("");
    setResults([]);
    source.clear(); // Haritadaki markerı kaldır
  };

  return (
    <div style={{ padding: "20px", maxWidth: "500px", margin: "0 auto" }}>
      <div style={{ display: "flex", marginBottom: "10px" }}>
        <Input
          placeholder="Enter address..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          style={{ flex: 1 }}
        />
        {query && (
          <Button
            type="text"
            icon={<CloseCircleOutlined />}
            onClick={clearSearch}
            style={{ marginLeft: "8px", color: "#ff4d4f" }}
          />
        )}
      </div>
      <Button
        type="primary"
        onClick={searchAddresses}
        loading={loading}
        style={{ marginBottom: "20px", width: "100%" }}
      >
        Search Address
      </Button>
      {loading ? (
        <Spin tip="Searching..." />
      ) : (
        <List
          bordered
          dataSource={results}
          renderItem={(feature) => (
            <List.Item
              key={feature.id}
              actions={[
                <Button
                  type="text"
                  icon={<ArrowRightOutlined />}
                  onClick={() => zoomToFeature(feature.geometry.coordinates)}
                  style={{ fontSize: "16px", color: "#1890ff" }}
                />,
              ]}
            >
              {feature.properties.gfullname}
            </List.Item>
          )}
        />
      )}
    </div>
  );
};

export default AddressSearch;
